import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import styled from "styled-components"
import BlogLayout from "../components/BlogLayout"
import { SanitySeo } from "../types/seo"
import { PortableText } from "@portabletext/react"

// Styled components
const ArticleContainer = styled.article`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
`

const StyledBackLink = styled.div`
  margin-bottom: 1.5rem;
  
  a {
    color: #3b82f6;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`

const PostTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
`

const PostDate = styled.p`
  color: #666;
  margin-bottom: 2rem;
`

const ImageContainer = styled.div`
  margin-bottom: 2rem;
  
  img {
    border-radius: 0.5rem;
  }
`

const PostContent = styled.div`
  line-height: 1.8;
  color: #333;
`

// TypeScript interfaces
interface SanityImage {
  asset: {
    gatsbyImageData: IGatsbyImageData;
  };
}

interface SanitySlug {
  current: string;
}

interface SanityBlock {
  _type: 'block';
  children: Array<{
    _type: string;
    text: string;
    marks?: string[];
  }>;
  style: string;
}

interface SanityPost {
  title: string;
  publishedAt: string;
  _createdAt: string;
  slug: SanitySlug;
  body?: SanityBlock[]; // Structured body content
  _rawBody?: any; // Raw JSON body content for PortableText
  image?: SanityImage;
  seo?: SanitySeo; // Using the imported SEO interface
}

interface BlogPostTemplateProps {
  data: {
    sanityPost: SanityPost;
  };
}

// Component
const BlogPostTemplate: React.FC<BlogPostTemplateProps> = ({ data }) => {
  const post = data.sanityPost
  const postImage = post.image?.asset ? getImage(post.image.asset) : null
  
  // Render the post content
  const renderPostContent = () => {
    if (post._rawBody) {
      return <PortableText value={post._rawBody} />;
    }
    return <p>This is a placeholder for the blog post content. The actual content will be displayed when the Sanity schema is properly configured.</p>;
  };
  
  return (
    <BlogLayout
      title={post.title}
      description={post.seo?.metaDescription || ""}
      pathname={`/blog/${post.slug.current}`}
      article={true}
      seo={post.seo}
    >
      <ArticleContainer>
        <StyledBackLink>
          <Link to="/blog">← Back to all posts</Link>
        </StyledBackLink>
        
        <PostTitle>
          {post.title}
        </PostTitle>
        
        <PostDate>
          Published on {new Date(post.publishedAt || post._createdAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
        </PostDate>
        
        {postImage && (
          <ImageContainer>
            <GatsbyImage
              image={postImage}
              alt={post.title}
            />
          </ImageContainer>
        )}
        
        <PostContent>
          {renderPostContent()}
        </PostContent>
      </ArticleContainer>
    </BlogLayout>
  )
}

// GraphQL query
export const query = graphql`
  query BlogPostQuery($id: String!) {
    sanityPost(id: { eq: $id }) {
      title
      publishedAt
      _createdAt
      slug {
        current
      }
      _rawBody
      body {
        _type
        style
        children {
          _type
          text
          marks
        }
      }
      image {
        asset {
          gatsbyImageData(width: 1200, placeholder: BLURRED)
        }
      }
      seo {
        nofollowAttributes
        metaTitle
        metaDescription
        metaImage {
          asset {
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
        }
        seoKeywords
        openGraph {
          url
          title
          description
          siteName
          image {
            asset {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        additionalMetaTags {
          metaAttributes {
            attributeKey
            attributeType
            attributeValueString
            attributeValueImage {
              asset {
                gatsbyImageData(width: 800, placeholder: BLURRED)
              }
            }
          }
        }
        twitter {
          cardType
          creator
          site
          handle
        }
      }
    }
  }
`

export default BlogPostTemplate